<template>
    <b-card-body class="pb-0">
        <b-row>
            <b-col md="6" class="m-auto">
                <slot name="header-left"></slot>
            </b-col>
            <b-col md="6" class="text-right">
                <b-button class="my-1 mx-1" variant="secondary" size="sm" @click="isFiltersVisible = !isFiltersVisible">{{ isFiltersVisible ? 'Скрыть' : 'Показать' }} фильтр</b-button>
                <slot name="header-right"></slot>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="12">
                <b-collapse v-model="isFiltersVisible">
                    <b-row class="mt-4">
                        <slot name="filters"></slot>
                    </b-row>
                </b-collapse>
            </b-col>
        </b-row>
    </b-card-body>
</template>

<script>
export default {
    name: 'FilterBlock',
    data() {
        return {
            isFiltersVisible: false,
        };
    },
};
</script>
